import numeral from 'numeral';
import Vue from "vue"


export const formatNumber = (value) => {
  return numeral(value).format("0,0.00")
}

export const formatOrderNumber = (value) => {
  return "CORS-" + String(value).padStart(5, '0')
}

export const replaceUndefined = (value) => {
  if (value == undefined) {
    return '--';
  }
  else {
    return value;
  }
}

Vue.filter('formatNumber', formatNumber);
Vue.filter('formatOrderNumber', formatOrderNumber);

